<template>
  <div id="app">
    
    <div id="header" >
    <div id="top">
      <img src="img/logo.svg" class="logo" alt="logotype">
      <div></div>
    </div>
    <div id="stories" >
      <ul v-if='lang == "eng"'>
        <li v-for="story in stories_list_en">
          <div :data-id="story.id" @click="storyOpen(story.id, story.content)">
            <div class="visual"><img :src="story.img" width="65" height="65" :alt="story.text"></div>
            <p>{{ story.text }}</p>
          </div>
        </li>
        <li>
          <div data-id="contact" @click="contactOpen()">
            <div class="visual"><img src="img/story-6.webp" width="65" height="65" alt="contact me"></div>
            <p>contact</p>
          </div>
        </li>
      </ul>
      <ul v-if='lang == "ukr"'>
        <li v-for="story in stories_list_ukr">
          <div :data-id="story.id" @click="storyOpen(story.id, story.content)">
             <div class="visual"><img :src="story.img" width="65" height="65" :alt="story.text"></div>
            <p>{{ story.text }}</p>
          </div>
        </li>
        <li>
          <div data-id="contact" @click="contactOpen()">
            <div class="visual"><img src="img/story-6.webp" width="65" height="65" alt="contact me"></div>
            <p>контакт</p>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div id="posts" >
    <ul v-if='lang == "eng"'>
      <li v-for="post in posts_eng">
        <div class="post" :data-id="post.id" :id="'post'+post.id">
          <div class="visual" @click="doubleTap(post.id)">
            <img :src="post.img" alt="post image">
          </div>
          <div class="promotion">
            <div class="like" :data-id="post.id" @click="postLike(post.id)">
             <div class="heart icon"></div>
            </div>
            <div class="repost" id="repost" @click="share(post.id)">
              <span>share</span>
            </div>
          </div>
          <div class="content">
            <p v-html="post.text">{{ post.text }}</p>
          </div>
        </div>
      </li>
    </ul>

    <ul v-if='lang == "ukr"'>
      <li v-for="post in posts_ukr">
        <div class="post" :data-id="post.id" :id="'post'+post.id">
          <div class="visual" @click="doubleTap(post.id)">
            <img :src="post.img">
          </div>
          <div class="promotion">
            <div class="like" :data-id="post.id" alt="post image" @click="postLike(post.id)">
              <div class="heart icon"></div>
            </div>
            <div class="repost" @click="share(post.id)">
              <span>поділитись</span>
            </div>
          </div>
          <div class="content">
            <p>{{ post.text }}</p>
          </div>
        </div>
      </li>
    </ul>

  </div>

  <div id="lang" v-bind:class="[lang]">
    <div @click="changeUkr">українська</div>
    <div @click="changeEng">english</div>
  </div>

  <div id="stories-ouput" >
    <div class="loading">
      <div class="download icon"></div>
    </div>
    <div data-slide="slide" class="slide">
      <div class="slide-items">
        <img :src="story.img" alt="story image" v-for="story in stories_active" loading="lazy">
      </div>
      <nav class="slide-nav">
        <div class="slide-thumb"></div>
        <button class="slide-prev">Anterior</button>
        <button class="slide-next">Próximo</button>
        <div class="escape">
        <div class="arrow-right icon"></div>
      </div>
      </nav>
    </div>
  </div>

  <code v-pre>
  <preloader>
    <img src="img/logo.svg" class="logo" alt="logotype">
  </preloader>
  </code>

  <div class="footer">
    <a href='https://github.com/nonconformiste/'>Github</a>
    <img src="img/footer.svg" alt="barcode" width="100" height="43">
  </div>

  <div id="contact-form">
    <div class="close" @click="contactClose()" ><div class="arrow-down icon" style="display: none;"></div></div>
    <a href="https://t.me/nonconformistee">telegram</a>
    <span v-if="lang == 'eng'">or</span>
    <span v-if="lang == 'ukr'">або</span>
    <div class="form">
    <input type="text" name="title" v-bind:placeholder="cphTitle" v-model="contactTitle">
    <input type="text" name="contact" v-bind:placeholder="cphContact" v-model="contactInfo">
    <textarea name="message" v-bind:placeholder="cphMsg" v-model="contactMessage"></textarea> 

    <button class="send" @click="toSend()" v-if="lang == 'eng'">send</button>
    <button class="send" @click="toSend()" v-if="lang == 'ukr'">надіслати</button>

    </div>
    <p>{{ contactNotice }}</p>
  </div>

  </div>

</template>

<script>


export default {
  name: 'App',
  data(){
    return{
    lang: "eng",
    copyMsg: "copied to clipboard ✏️",
    contactTitle: '',
    contactInfo: '',
    contactMessage: '',
    contactNotice: '',
    cphTitle: 'subject',
    cphContact: 'contact information',
    cphMsg: 'message',
    stories_active: [],
    stories_list_en: [
      { id: "1", text: "about", img: "img/story-1.webp", content: [
        { id: "1.1", img: "img/s11en.webp"},
        { id: "1.2", img: "img/s12en.webp"}
      ] },
      { id: "2", text: "news", img: "img/story-2.webp", content: [
        { id: "2.1", img: "img/s21en.webp"}
      ] },
      { id: "3", text: "rate", img: "img/story-3.webp", content: [
        { id: "3.1", img: "img/s31.webp"}
      ] },
      { id: "4", text: "stack", img: "img/story-4.webp", content: [
        { id: "4.1", img: "img/s41.webp"}
      ] },
      { id: "5", text: "reviews", img: "img/story-5.webp", content: [
        { id: "5.1", img: "img/s51.webp"},
        { id: "5.2", img: "img/s52.webp"},
        { id: "5.3", img: "img/s53.webp"},
        { id: "5.4", img: "img/s54.webp"},
        { id: "5.5", img: "img/s55.webp"},
        { id: "5.6", img: "img/s56.webp"}
      ] }
    ],
    stories_list_ukr: [
      { id: "1", text: "про мене", img: "img/story-1.webp", content: [
        { id: "1.1", img: "img/s11ukr.webp"},
        { id: "1.2", img: "img/s12ukr.webp"}
      ] },
      { id: "2", text: "новини", img: "img/story-2.webp", content: [
        { id: "2.1", img: "img/s21ukr.webp"}
      ] },
      { id: "3", text: "рейт", img: "img/story-3.webp", content: [
        { id: "3.1", img: "img/s31.webp"}
      ] },
      { id: "4", text: "стек", img: "img/story-4.webp", content: [
        { id: "4.1", img: "img/s41.webp"}
      ] },
      { id: "5", text: "відгуки", img: "img/story-5.webp", content: [
        { id: "5.1", img: "img/s51.webp"},
        { id: "5.2", img: "img/s52.webp"},
        { id: "5.3", img: "img/s53.webp"},
        { id: "5.4", img: "img/s54.webp"},
        { id: "5.5", img: "img/s55.webp"},
        { id: "5.6", img: "img/s56.webp"}
      ] }
    ],
    posts_ukr: [
    { id: 2, img: "img/banner_ukr.webp", text: "привіт. я не студія 🙅‍♂️. я нонконформіст у світі веб-розробки. якщо тобі також вже набридли ці фрилансери які відсилають копіпаст у якості результату, тоді тобі до мене🤘🏻."}
    ],
    posts_eng: [
    { id: 1, img: "img/supportukraine.png", text: "how to help Ukraine? you can make a donation to the charitable organization <a href='https://www.comebackalive.in.ua/'>Come Back Alive</a>. you can help Ukrainian refugees in your country. you can ask your government for heavy and small arms for ukraine."},
    { id: 2, img: "img/banner_en.webp", text: "hi.i'm not a studio 🙅‍♂️. i am nonconformist in world of web development. if you are also tired of these freelancers who send 'copypaste sh*t' as a result, then you to me🤘🏻."}
    ]
    }
  },
  methods: {

    storyOpen(id, content){
      this.stories_active = content;
      storyOpenEffect(id);
    },

    changeUkr(){
      storyClose();
      this.lang = "ukr";
      this.copyMsg = "скопійовано ✏️";
      this.lang = "ukr";
      this.cphTitle = 'тема';
      this.cphContact = 'контактна інформація';
      this.cphMsg = 'повідомлення';
    },

    changeEng(){
      storyClose();
      this.lang = "eng";
      this.copyMsg = "copied to clipboard ✏️";
      this.cphTitle = 'subject';
      this.cphContact = 'contact information';
      this.cphMsg = 'message';
    },

    postLike(id){
      makeItLike(id);
    },

    share(id){

        if (navigator.share) {
            navigator.share({
                    title: "",
                    text: "",
                    url: window.location.href +'#post'+id
                })
                .then(function () {
                   
                })
                .catch(function () {
                    
                })

        } else {

          var str = window.location.href +'#post'+id;
          const el = document.createElement('textarea');
          el.value = str;
          el.setAttribute('readonly', '');
          el.style.position = 'absolute';
          el.style.left = '-9999px';
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);

          showMessage(this.copyMsg);
          
        }
    },

    contactOpen(){
      document.querySelector('body').classList.add('contact-openned');
    },

    contactClose(){
      document.querySelector('body').classList.remove('contact-openned');
    },

    toSend(){
      if ( this.contactTitle.length > 0 & this.contactInfo.length > 0 & this.contactMessage.length > 0 ) {
        sendContact(this.contactTitle,this.contactInfo,this.contactMessage);
         if ( this.lang == "ukr" ) {
          this.contactNotice = "Місія виконана 🗿🗿🗿";
        } else {
          this.contactNotice = "Mission complete 🗿🗿🗿"
        }
        
      }
      else {
        if ( this.lang == "ukr" ) {
          this.contactNotice = "Треба заповнити всі поля 👆";
        } else {
          this.contactNotice = "Must to fill all fields 👆";
        }

      }
    }

  },

  beforeMount(){

    if ( window.navigator.language === "uk-UK" || window.navigator.browserLanguage === "uk-UK" || window.navigator.userLanguage === "uk-UK" || window.navigator.systemLanguage === "uk-UK" ) {
          this.lang = "ukr";
          this.cphTitle = 'тема';
          this.cphContact = 'контактна інформація';
          this.cphMsg = 'повідомлення';
          this.copyMsg = "скопійовано ✏️";
      } else  {
          this.lang = "eng";
          this.cphTitle = 'subject';
          this.cphContact = 'contact information';
          this.cphMsg = 'message';
      }

  },
  created() {

    /* Connect script */
    let frontend = document.createElement('script');
    frontend.setAttribute('src',"js/frontend.js");
    document.body.appendChild(frontend);

    /* Connect styles */
    let styles = document.createElement('link');
    styles.setAttribute('rel',"stylesheet");
    styles.setAttribute('type',"text/css");
    styles.setAttribute('href',"css/style.css");
    document.head.appendChild(styles);

    /* Connect fonts */
    let fonts = document.createElement('link');
    fonts.setAttribute('rel',"stylesheet");
    fonts.setAttribute('type',"text/css");
    fonts.setAttribute('href',"fonts/style.css");
    document.body.appendChild(fonts);

  }
}
</script>
